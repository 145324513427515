.gallery {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 1fr);
    row-gap: 10px;
    column-gap: 15px;
  }
  
  .gallery img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .gallery img.fade {
    opacity: 1;
  }